import React from "react";
import cx from "classnames";

export const Input = (props) =>
{
    const { label, step, name, type, error, customClass, onChange, value, min, disabled } = props;

    return <>
        {label ? <label className="text-exploratory-green-400 mb-2 text-[14px] block text-[14px]" for={name}>
            {label}
        </label> : null}

        <input
            name={name}
            type={type}
            step={step}
            className={cx(
                `w-full background-white text-expertise-gray text-[14px])`,
                customClass,
                {
                    "!border-[#DD2018]": error,
                    "border-none": !error
                })}
            value={value}
            onChange={onChange}
            min={min}
            disabled={disabled}
        />

        {error ? <div className="inputError">{error}</div> : null}
    </>
}