import React, { useEffect, useLayoutEffect, useState } from 'react';
import { SwitchToggle } from '../../style/SwitchToggle';
import { Heading } from '../../style/Heading';
import { Button } from '../../style/Button';
import { Input } from './components/Input';
import { Select } from './components/Select';
import CheckIcon from '../../style/icons/check';
import { IconCall } from "../../componentsTailwind/Icons";
import { useCalculation } from './hooks/use-calculation';
import { Spinner } from '../../style/SpinnerDarkGreen ';
import { MarkupToHtml } from "../../helpers/parse";
import { Text } from "../../style/Text";
import { Flex } from "../../style/Grid";
import { Radio } from "../../style/Radio";
import { formatPostCode, formatPrice } from "../../formUtils";
import config from "../../configs";
import { OF_FIRST_STEP_SLUG } from '../../constants';
import { usePrevious } from '../../helpers/hooks/use-previous';
import { SavingsCalculationHeading, CalculationLocationRadio, BonusCalculationImage } from '../../style/SavingsCalculation/SavingsCalculation';

const CHECK_ICON_SIZE = 12;
export const CALCULATOR_ID = "kalkulacka";

export const SavingsCalculation = ({ offers: offersData, data: { bottomResultText, bottomResultLink, gasCompetitionOfferCode, eleCompetitionOfferCode }, onResultChange, analyticsData, options = undefined }) =>
{
    const offerCodes = offersData?.map(offer => ({ offerCode: offer.offerCode, commodity: offer.commodity, type: offer.type, textList: offer.textList, isBonus: offer.isBonus })) || [];
    const {
        selected,
        changeCommodity,
        showResult,
        changeParameters,
        circuitBreakers,
        rateId,
        setRateId,
        breakerId,
        setBreakerId,
        isCalculationLoading,
        fetchCalculation,
        consumption,
        isConsumptionValid,
        setConsumption,
        consumptionLowTariff,
        isConsumptionLowTariffValid,
        setConsumptionLowTariff,
        consumptionHighTariff,
        isConsumptionHighTariffValid,
        setConsumptionHighTariff,
        postCode,
        setPostCode,
        isValid,
        calculationResult,
        isFixed,
        offerCode,
        setActiveOffer,
        unitsOptions,
        selectedUnit,
        setUnit,
        commodity,
        errorMessage,
        ratesOptions,
        rate,
        allowsBothTariffs,
        textList,
        type,
        changeType,
        phase,
        changePhase,
        selectedBreaker,
        maxAmperes,
        setMaxAmperes,
        totalPrice,
        pricePerMonth,
    } = useCalculation(offerCodes, {
        gas: gasCompetitionOfferCode || "PZR_STROP",
        ele: eleCompetitionOfferCode || "PROUD_STROP",
    },
        onResultChange,
        analyticsData,
        options
    );
    
    const showResultPrev = usePrevious(showResult);
    const isCalculationLoadingPrev = usePrevious(isCalculationLoading);

    const isSelectedGas = selected === 0;
    const title = "Spočítejte si platby";
    const resultTitle = isSelectedGas ? "Platby za plyn u MND" : "Platby za elektřinu u MND";

    // ## Switch on unsent form
    const offersSwitchOptions = [{ switchText: "Plyn" }, { switchText: "Elektřina" }];
    const noCommoditySwitch = options?.form?.noCommoditySwitch;

    // ## Switch on sent form
    const resultOffers = [{ switchText: "s fixací" }, { switchText: "s garancí" }];
    const noResultTypeSwitch = options?.result?.noResultTypeSwitch;

    const breakersOptions = (circuitBreakers || []).map(breake => ({ value: breake.id, label: breake.name }));
    const resultAdvatages = textList.map(({ text }, index) => ({ id: index, content: text }));
    const nameForCustomer = calculationResult?.mnd.nameForCustomer || null;
    const priceListLink = calculationResult?.priceListLink || null;
    const priceListName = nameForCustomer && nameForCustomer.split("gie/").pop();
    const stateText = isSelectedGas ? (
        `Pro spotřebu ${consumption} ${selectedUnit?.label || '/'}, PSČ ${formatPostCode(postCode)}`
    ) : (
        `Pro sazbu ${rate?.shortName || '/'} ${consumptionHighTariff && `se spotřebou ${consumptionHighTariff} ${selectedUnit?.label || '/'} VT` || ''}${allowsBothTariffs && consumptionLowTariff && `, ${consumptionLowTariff} ${selectedUnit?.label || '/'} NT` || ''}, jistič ${selectedBreaker?.name || '/'}, PSČ ${formatPostCode(postCode)}`
    );
    const offerCodesByCommodity = offerCodes.filter(offer => offer.commodity === commodity);
    const eleInvalidText = "Elektřinu lze online sjednat pouze do výše spotřeby 100 MWh.";
    const gasInvalidText = "Plyn lze online sjednat pouze do výše spotřeby 150 MWh.";

    const arrangeOfferAdditionalParams = options?.result?.arrangeButton?.offerAdditionalParams || "";
    const arrangeOnlineUrl = `${config.gofu}${isSelectedGas ? "plyn" : "elektrina"}/${OF_FIRST_STEP_SLUG}${(offerCode && `?product=${offerCode}${arrangeOfferAdditionalParams}`) || ""}`;

    // ## Location radio (domácnost, firma)
    const householdRadioDisabled = options?.form?.locationRadio?.householdDisabled;
    const companyRadioDisabled = options?.form?.locationRadio?.companyDisabled;

    const onSubmit = (e) =>
    {
        e.preventDefault();

        if (isValid)
        {
            fetchCalculation();
        }

        return false;
    }

    const [scheduleScroll, setScheduleScroll] = useState(null);
    const scheduleScrollPrev = usePrevious(scheduleScroll);

    useLayoutEffect(() => {
        if(scheduleScrollPrev === undefined || scheduleScroll === null)
        {
            return;
        }

        if(scheduleScroll !== scheduleScrollPrev)
        {
            scrollIntoView();
        }

    }, [scheduleScroll, scheduleScrollPrev]);

    const scheduleScrollIntoView = () =>
    {
        setScheduleScroll(!scheduleScroll);
    }

    const scrollIntoView = () =>
    {
        const target = document.getElementById(CALCULATOR_ID);

        if (target)
        {
            target.scrollIntoView();
        }
    }

    useEffect(() =>
    {
        if ((
            typeof showResultPrev !== 'undefined' &&
            showResult !== showResultPrev
        ))
        {
            if(!showResult)
            {
                return;
            }
            
            scrollIntoView();
        }
    }, [showResult]);

    const onChange = () =>
    {
        changeParameters();
        // musíme zavolat až při dalším renderu, protože zatím ještě není updatenutý state showResult
        // z changeParameters();
        scheduleScrollIntoView();
    }

    const priceBonus = options?.bonus?.priceBonus || 0;
    const hasPriceBonus = options?.bonus?.isUsingBonus || false;

    const discountBonusPrice = totalPrice - priceBonus < 0 ? 0 : totalPrice - priceBonus;

    const hideUnderformPDF = !!options?.result?.hideUnderformPDF;

    return <div
        className={`flex justify-center py-12 md:py-15${showResult ? ' max-w-[800px] md:px-[54px] px-5' : ' max-w-[807px] md:pl-[105px] md:pr-[35px] px-5'} mx-auto`}
    >
        {showResult ? (
            <div
                className="flex flex-col gap-6 items-center text-exploratory-green-400 w-full"
            >
                <SavingsCalculationHeading
                    as="h2"
                    fontSize={["26px", "26px", "36px"]}
                    fontWeight="700"
                    color="exploratoryGreen"
                    mb="0"
                    display={"flex"}
                    css={{ textAlign: "center" }}
                    id={CALCULATOR_ID}
                >
                    <MarkupToHtml>{resultTitle}</MarkupToHtml>
                </SavingsCalculationHeading>

                {!noResultTypeSwitch && (
                <SwitchToggle
                    offersLength={offersSwitchOptions.length}
                    selected={selected}
                    backgroundColor="#FFF"
                    textColor={"green"}
                    onClick={() =>
                    {
                        setActiveOffer(offerCodesByCommodity.find(offer => offer.offerCode !== offerCode));
                    }}
                >
                    <div>
                        {resultOffers.map((offer, index) => (
                            <span key={`offer-option-${index}`} className={isFixed && 0 === index || !isFixed && 1 === index ? "active" : ""}>
                                {offer.switchText}
                            </span>
                        ))}
                    </div>
                </SwitchToggle>
                )}
                <div className='flex text-[14px] items-end'>
                    <div className="mr-4">
                        {stateText}
                    </div>
                    <button className="text-exploratory-green-400 underline" onClick={onChange}>Změnit</button>
                </div>
                {
                    isCalculationLoading ? (
                        <div className="flex md:flex-row flex-col py-8 md:py-10 px-5 md:px-6 gap-6 w-full relative" style={{
                            minHeight: "278px"
                        }}>
                            <Spinner />
                        </div>)
                        : (
                            <>
                                <div className="flex md:flex-row flex-col bg-white py-8 md:py-10 px-5 md:px-6 gap-6 w-full border-[1px] border-[#A8BE32] border-solid">
                                    <div className="flex md:gap-2 flex-col gap-0 flex-1">
                                        <div className="flex">
                                            <div className='mt-[5px] mx-[2.5px] relative top-[-15px]'>
                                                {isSelectedGas
                                                    ? <img src={require("../../images/HP_dark.gif").default} alt="Proud" width="35px" height="61px" className='mb-0' />
                                                    : <img src={require("../../images/HP_el_dark.gif").default} alt="Proud" width="35px" height="61px" className='mb-0' />
                                                }
                                            </div>
                                            <div className='ml-1'>
                                                <div className="mb-0 md:mb-1.5 text-[18px] font-bold">MND</div>
                                                <div className="text-[14px]">{priceListName}</div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-[5px]">
                                            {resultAdvatages.map((advantage) => <div key={advantage.id} className="flex gap-4 ml-3.5 text-exploratory-green-400 text-small">
                                                <div className="mt-0.5">
                                                    <CheckIcon width={CHECK_ICON_SIZE} height={CHECK_ICON_SIZE} />
                                                </div>
                                                <div>
                                                    <MarkupToHtml>{advantage.content}</MarkupToHtml>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                    <div className='md:w-[1px] md:h-full w-full h-[1px] bg-light-grey'></div>
                                    <div className='flex-1'>
                                        <div className='flex items-end align-baseline'>
                                            <Text fontSize="18px" color="exploratoryGreen" fontWeight="800">
                                                Platba za rok
                                            </Text>
                                            <Text fontSize="26px" color="exploratoryGreen" fontWeight="800" ml="auto">
                                                {formatPrice(totalPrice)}
                                            </Text>
                                        </div>
                                        <div className='flex justify-between items-end font-bold align-baseline'>
                                            <div className='text-[14px]'>Očekáváná měsíční záloha</div>
                                            <div className="text-[18px]">{formatPrice(pricePerMonth)}</div>
                                        </div>
                                        {hasPriceBonus && (
                                            <>
                                            <div className="flex justify-between items-end font-bold align-baseline">
                                                <div className="text-[14px]">
                                                <Text color="green.light">Jednorázový bonus</Text>
                                                </div>
                                                <div className="text-[18px]">
                                                <Text color="green.light">-{formatPrice(priceBonus)}</Text>
                                                </div>
                                            </div>

                                            <div className="flex items-end align-baseline">
                                                <Text fontSize="18px" color="green.light" fontWeight="800">
                                                Platba za 1. rok
                                                </Text>
                                                <Text fontSize="26px" color="green.light" fontWeight="800" ml="auto">
                                                {formatPrice(discountBonusPrice)}
                                                </Text>
                                            </div>
                                            </>
                                        )}
                                        <div className='flex items-end'>
                                            <Text fontSize="14px" color="exploratoryGreen" fontWeight="400" ml="auto" mb="0" mt="7px">
                                                Ceny s DPH
                                            </Text>
                                        </div>
                                        <div className='flex justify-center'>
                                            <Button as="a" href={arrangeOnlineUrl} target="_blank" height="55px" width={["210px"]} fontSize="18px" fontWeight="600" mt={"30px"}>
                                                Sjednat online
                                            </Button>
                                        </div>
                                        <div className='flex items-center mt-6 gap-2 justify-center'>
                                            <IconCall className="w-4 h-4 fill-[#2B6E33]" />
                                            <a href={bottomResultLink} className='text-exploratory-green-400 text-[14px]'>{bottomResultText}</a>
                                        </div>
                                    </div>
                                </div>
                                {!hideUnderformPDF && (
                                <a href={priceListLink} target="_blank" className='flex gap-3 text-exploratory-green-400 text-[14px]'>
                                    <img src={require("../../images/pdf.png").default} width="20px" height="20px" alt="Cenik" className='mb-0' />
                                    {priceListName}
                                </a>
                                )}
                            </>
                        )}
            </div >
        ) : (
            <form onSubmit={onSubmit} action='#' className='w-full' formNoValidatef>
                <div className="flex flex-col md:flex-row items-center md:items-start w-full">
                    <Flex flex={"1 2"}>
                    {hasPriceBonus ? (
                        <BonusCalculationImage className="mt-0 md:mt-5" src={require("../../images/savings-calculator-bonus.png").default} alt="Kalkulačka" />
                    ) : (
                        <img className="mb-0 mt-0 md:mt-5" src={require("../../images/savings-calculator.png").default} width={240} alt="Kalkulačka" />
                    )}
                    </Flex>
                    <Flex
                        flex={"1 2"}
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems={["flex-center", "flex-start"]}
                        mt={hasPriceBonus ? 0 : [5, 0]}
                    >
                        <SavingsCalculationHeading
                            as="h2"
                            fontSize={["22px", "22px"]}
                            fontWeight="700"
                            color="exploratoryGreen"
                            mb="24px"
                            display={"flex"}
                            css={{ textTransform: "uppercase", textAlign: "center" }}
                            id={CALCULATOR_ID}
                        >
                            <MarkupToHtml>{title}</MarkupToHtml>
                        </SavingsCalculationHeading>

                        {!noCommoditySwitch && (
                        <div className="mb-4 flex justify-center md:justify-start">
                            <SwitchToggle
                                offersLength={offersSwitchOptions.length}
                                selected={selected}
                                backgroundColor="#FFF"
                                textColor={"green"}
                                onClick={offersSwitchOptions.length > 1 ? () => changeCommodity(s => Number(!s)) : undefined}
                                type="button"
                            >
                                <div>
                                    {offersSwitchOptions.map((offer, index) => (
                                        <span key={`offer-option-${index}`} className={selected === index ? "active" : ""}>
                                            {offer.switchText}
                                        </span>
                                    ))}
                                </div>
                            </SwitchToggle>
                        </div>
                        )}
                        <div className="flex gap-4 mb-4">
                            <div className="flex-[2_1_0%] md:flex-[7_1_0%]">
                                <Flex flexDirection={["column", "row", null]} mt="10px">
                                    <CalculationLocationRadio disabled={householdRadioDisabled}>
                                        <label>
                                            <input
                                                name="type"
                                                type="radio"
                                                value="domacnost"
                                                checked={type === "domacnost"}
                                                onChange={() => changeType("domacnost")}
                                                disabled={householdRadioDisabled}
                                            />
                                            Domácnost
                                            <span />
                                        </label>
                                    </CalculationLocationRadio>
                                    <CalculationLocationRadio disabled={companyRadioDisabled}>
                                        <label>
                                            <input
                                                name="type"
                                                type="radio"
                                                value="firma"
                                                checked={type === "firma"}
                                                onChange={() => changeType("firma")}
                                                disabled={companyRadioDisabled}
                                            />
                                            Firma
                                            <span />
                                        </label>
                                    </CalculationLocationRadio>
                                </Flex>
                            </div>
                        </div>
                        {isSelectedGas ? (
                            <div className='max-w-[210px] md:max-w-none w-full md:w-auto'>
                                <div className="flex gap-4 mb-4">
                                    <div className="flex-[82_1_0%] md:flex-[7_1_0%]">
                                        <Input
                                            type='number'
                                            name="Consumption"
                                            label="Spotřeba"
                                            value={consumption}
                                            min={0}
                                            onChange={e => setConsumption(parseFloat(e.target.value))}
                                        />
                                        {!isConsumptionValid && <div className="inputError">{gasInvalidText}</div>}
                                    </div>
                                    <div className="flex-[111_1_0%] md:flex-[5_1_0%]">
                                        <Select
                                            name="consumptionUnits"
                                            options={unitsOptions}
                                            customClass="mt-[29px]"
                                            value={selectedUnit?.value || ""}
                                            onChange={e => setUnit(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-4'>
                                    <div className="flex-1 md:flex-[7_1_0%]">
                                        <Input
                                            type='number'
                                            name="postCode"
                                            label="PSČ odběrného místa"
                                            customClass="input-no-control"
                                            value={postCode}
                                            min={0}
                                            onChange={e => setPostCode(parseInt(e.target.value))}
                                        />
                                    </div>
                                    <div className="hidden md:block md:flex-[5_1_0%]"></div>
                                </div>
                            </div>
                        ) : (
                            <div className='max-w-[335px] md:max-w-none w-full md:w-auto'>
                                <div className="flex gap-4 mb-4">
                                    <div className="gap-4">
                                        <Select
                                            name="tariff"
                                            options={ratesOptions}
                                            placeholder='Vyberte'
                                            label="Sazba"
                                            onChange={e =>
                                            {
                                                setRateId(parseInt(e.target.value));
                                                setBreakerId(null);
                                            }}
                                            value={rateId || ""}
                                        />
                                    </div>
                                    <div className="flex-[1_1_0%] md:flex-[5_1_0%]"></div>
                                </div>
                                <div className="flex gap-4 mb-4">
                                    <div className="">
                                        <label className="text-exploratory-green-400 mb-2 text-[14px] block" for={"phase"}>
                                            Fáze
                                        </label>
                                        <Flex flexDirection={["column", "row", null]} mt="10px">
                                            <Radio>
                                                <label>
                                                    <input
                                                        name="phase"
                                                        type="radio"
                                                        value="3"
                                                        checked={phase === 3}
                                                        onChange={() => changePhase(3)}
                                                    />
                                                    3
                                                    <span />
                                                </label>
                                            </Radio>
                                            <Radio>
                                                <label>
                                                    <input
                                                        name="phase"
                                                        type="radio"
                                                        value="3"
                                                        checked={phase === 1}
                                                        onChange={() => changePhase(1)}
                                                    />
                                                    1
                                                    <span />
                                                </label>
                                            </Radio>
                                        </Flex>
                                    </div>
                                    <div className="flex-[1_1_0%] md:flex-[5_1_0%]">
                                        <Select
                                            name="circuitBreaker"
                                            placeholder='Vyberte'
                                            options={breakersOptions}
                                            label="Jistič"
                                            onChange={e => setBreakerId(parseInt(e.target.value))}
                                            value={breakerId || ""}
                                            disabled={!rateId}
                                        />
                                    </div>
                                </div>
                                {selectedBreaker?.requiresMaxAmperes && (
                                    <div className="flex gap-4 mb-4">
                                        <div className="flex-[2_1_0%] md:flex-[7_1_0%] flex gap-4">
                                            <div>
                                                <Input
                                                    type="number"
                                                    name="maxAmperes"
                                                    label="Počet ampérů na jistič"
                                                    customClass="w-[100px]"
                                                    value={maxAmperes}
                                                    min={0}
                                                    onChange={e => setMaxAmperes(parseInt(e.target.value))}
                                                />
                                            </div>
                                            <div />
                                        </div>
                                        <div className="flex-[1_1_0%] md:flex-[5_1_0%]" />
                                    </div>
                                )}
                                <div className="flex gap-4 mb-4">
                                    <div className="flex-[2_1_0%] md:flex-[7_1_0%] flex gap-4">
                                        <div>
                                            <Input
                                                type='number'
                                                step="1"
                                                name="hightTariffConsumption"
                                                label="Spotřeba VT"
                                                value={consumptionHighTariff}
                                                min={0}
                                                onChange={e => setConsumptionHighTariff(parseFloat(e.target.value))}
                                            />
                                            {!isConsumptionHighTariffValid && <div className="inputError">{eleInvalidText}</div>}
                                        </div>
                                        {allowsBothTariffs && <div>
                                            <Input
                                                type='number'
                                                step="1"
                                                name="lowTariffConsumption"
                                                label="Spotřeba NT"
                                                value={consumptionLowTariff}
                                                min={0}
                                                onChange={e => setConsumptionLowTariff(parseFloat(e.target.value))}
                                            />
                                            {!isConsumptionLowTariffValid && <div className="inputError">{eleInvalidText}</div>}
                                        </div>}
                                    </div>
                                    <div className="flex-[1_1_0%] md:flex-[5_1_0%]">
                                        <Select
                                            as="select"
                                            options={unitsOptions}
                                            name="tariffConsumptionUnits"
                                            customClass="mt-[29px]"
                                            value={selectedUnit?.value || ""}
                                            onChange={e => setUnit(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="flex gap-4">
                                    <div className="flex-[2_1_0%] md:flex-[7_1_0%] gap-4">
                                        <Input
                                            type="number"
                                            name="postCode"
                                            label="PSČ odběrného místa"
                                            customClass="input-no-control"
                                            value={postCode}
                                            min={0}
                                            onChange={e => setPostCode(parseInt(e.target.value))}
                                        />
                                    </div>
                                    <div className="flex-[1_1_0%] md:flex-[5_1_0%]"></div>
                                </div>
                            </div>
                        )}
                        {errorMessage && (
                            <div className='w-full inputBlock'>
                                {errorMessage}
                            </div>
                        )}
                        <Button
                            type="submit"
                            disabled={!isValid}
                            height="55px"
                            width={["210px"]}
                            fontSize="18px"
                            fontWeight="600"
                            mt={"24px"}
                            formNoValidate
                        >
                            Spočítat
                        </Button>
                    </Flex>
                </div>
            </form>
        )}
    </div >
}