import styled, { css } from "styled-components";
import { FIXED_NAVIGATION_HEIGHT } from "../Navigation/FixedNavigationStyle";
import { Heading } from '../Heading';
import { Radio } from "../Radio";

const EXTRA_Y_OFFSET = 20;

export const SavingsCalculationHeading = styled(Heading)`
    scroll-margin-top: ${`${FIXED_NAVIGATION_HEIGHT + EXTRA_Y_OFFSET}px`};
`

export const CalculationLocationRadio = styled(Radio)`
  ${props => props.disabled && DisabledStyleCSS}
`;

const DisabledStyleCSS = css`
  opacity: 0.3;

  & > label {
    color: ${props => props.theme.colors.expertiseGray};
  }
`;

export const BonusCalculationImage = styled.img`
  margin-left: 40px;
  margin-right: 40px;
  width: auto;

  width: 164px;
  height: 299px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 100px;
    height: 184px;
  }

  filter: drop-shadow(4px 4px 20px ${props => props.theme.colors.black[15]});

  margin-bottom: 32px;
`;
