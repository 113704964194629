import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "../../../helpers/hooks/use-previous";
import { GAS, ELECTRICITY } from "../../../constants";

const round = (num) => Math.round((num + Number.EPSILON));

export const CALCULATION_COMMODITY_INDEX = Object.freeze({
    GAS: 0,
    ELECTRICITY: 1,
});

export const useCalculation = (offers, competitionOfferCodes, onResultChange, analyticsData, options = undefined) =>
{
    const initialData = options?.initialData;

    const [fetchCalculationRequested, setfetchCalculationRequested] = useState(false);
    const [selected, setSelected] = useState(initialData?.selected || 0);
    const [showResult, setShowResult] = useState(false);
    const [activeOfferCode, setActiveOfferCode] = useState(null);
    const [householdEleRates, setHouseholdEleRates] = useState([]);
    const [companyEleRates, setCompanyEleRates] = useState([]);
    const [circuitBreakers, setCircuitBreakers] = useState([]);
    const [rateId, setRateId] = useState(null);
    const [breakerId, setBreakerId] = useState(null);
    const [isCalculationLoading, setCalculationLoading] = useState(false);
    const [consumption, setConsumption] = useState("");
    const [consumptionLowTariff, setConsumptionLowTariff] = useState("");
    const [consumptionHighTariff, setConsumptionHighTariff] = useState("");
    const [postCode, setPostCode] = useState("");
    const [calculationResult, setCalculationResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const unitsOptions = [{ value: "mwh", label: 'MWh/rok' }, { value: "kwh", label: 'kWh/rok' }];
    const [unit, setUnit] = useState(unitsOptions[0].value);
    const [type, setType] = useState("domacnost");
    const [phase, setPhase] = useState(3);
    const [maxAmperes, setMaxAmperes] = useState(63);
    const selectedUnit = unitsOptions.find(u => u.value === unit) || null;
    const commodity = selected === 0 ? "gas" : "electricity";
    const isSelectedGas = commodity === "gas";
    const isHousehold = type === "domacnost";
    const eleRates = isHousehold ? householdEleRates : companyEleRates;
    

    // bonus
    const isUsingBonus = options?.bonus?.isUsingBonus || false;

    // filter all offers (gas, ele, ...) by selected commodity
    // and if it's a bonus savings calculator, pick only the bonus offers
    // if it's not a bonus savings calculator, pick only the non-bonus offers
    const offerCodes =
        offers?.filter(offer => {
        const isSelectedCommodity = offer.commodity === commodity;
        return isSelectedCommodity && offer.isBonus === isUsingBonus;
        }) || [];


    const isValidActiveOfferCode = (activeOfferCode && offerCodes.find(offer => offer.offerCode === activeOfferCode)) || false;
    const offerCode = (!isValidActiveOfferCode && offerCodes.length > 0 ? offerCodes[0].offerCode : activeOfferCode) || (isSelectedGas ? "PZR_FIX" : "PROUD_FIX");
    const ratesOptions = (eleRates || []).map(rate => ({ value: rate.id, label: rate.shortName }));
    const rate = (eleRates || []).find(rate => rate.id === rateId) || null;
    const allowsBothTariffs = rate ? Boolean(rate?.allowsBothTariffs) : true;
    const activeOffer = offerCodes?.find(offer => offer.offerCode === offerCode) || null;
    const isFixed = activeOffer?.type === "withFixation" || false;
    const prevOfferCode = usePrevious(offerCode);
    const textList = activeOffer?.textList || [];
    const consumptionInMwh = consumption && selectedUnit.value === "mwh" ? parseFloat(consumption) : parseFloat(consumption) / 1000;
    const isConsumptionValid = consumptionInMwh ? Boolean(consumptionInMwh && parseFloat(consumptionInMwh) < 150) : true;
    const consumptionLowTariffInMwh = consumptionLowTariff && selectedUnit.value === "mwh" ? parseFloat(consumptionLowTariff) : parseFloat(consumptionLowTariff) / 1000;
    const isConsumptionLowTariffValid = consumptionLowTariffInMwh ? Boolean(consumptionLowTariffInMwh && parseFloat(consumptionLowTariffInMwh) < 100) : true;
    const consumptionHighTariffInMwh = consumptionHighTariff && selectedUnit.value === "mwh" ? parseFloat(consumptionHighTariff) : parseFloat(consumptionHighTariff) / 1000;
    const isConsumptionHighTariffValid = consumptionHighTariffInMwh ? Boolean(consumptionHighTariffInMwh && parseFloat(consumptionHighTariffInMwh) < 100) : true;
    const selectedBreaker = (circuitBreakers || []).find(breaker => breaker.id === breakerId) || null;

    const validation = useCallback(() => (
        isSelectedGas ? Boolean(
            consumption &&
            isConsumptionValid &&
            postCode &&
            String(postCode).length === 5 &&
            !isCalculationLoading
        ) : Boolean(
            (allowsBothTariffs ? consumptionLowTariff && isConsumptionLowTariffValid : true) &&
            consumptionHighTariff &&
            isConsumptionHighTariffValid &&
            postCode &&
            rateId &&
            breakerId &&
            String(postCode).length === 5 &&
            !isCalculationLoading
        )
    ), [isSelectedGas,
        consumption,
        isConsumptionValid,
        postCode,
        isCalculationLoading,
        allowsBothTariffs,
        consumptionLowTariff,
        isConsumptionLowTariffValid,
        consumptionHighTariff,
        rateId,
        breakerId,
        isConsumptionHighTariffValid,
    ]);

    const isValid = validation();

    useEffect(() =>
    {
        fetchEleRatesList();
        listenForOfferCodeChange();
        processUrlParams();
    }, []);

    useEffect(() =>
    {
        if (rateId && phase)
        {
            fetchCircuitBreakersList(parseInt(rateId), phase);
        }
    }, [rateId, phase]);

    useEffect(() =>
    {
        if ((fetchCalculationRequested && isValid) || (showResult && isValid && offerCode !== prevOfferCode))
        {
            fetchCalculation();
        }
    }, [showResult, isValid, offerCode, prevOfferCode, fetchCalculationRequested]);

    useEffect(() =>
    {
        if(!onResultChange)
        {
            return;
        }

        onResultChange({
            commodity: isSelectedGas ? GAS : ELECTRICITY,
            consumption: consumptionInMwh,
            consumptionLowTariff: consumptionLowTariffInMwh,
            consumptionHighTariff: consumptionHighTariffInMwh,
            postCode,

        });
    }, [isSelectedGas, consumptionInMwh, consumptionLowTariffInMwh, consumptionHighTariffInMwh, postCode]);

    const listenForOfferCodeChange = () =>
    {
        window.addEventListener("activeOfferCode", (event) =>
        {
            setShowResult(false);
            setSelected(event.detail.commodity === "gas" ? 0 : 1);
            setActiveOfferCode(event.detail.offerCode);
        });
    }

    const fetchEleRatesList = () =>
    {
        fetch("/api/ele-rates-list", {
            method: "POST",
        })
            .then(response => response.json())
            .then(response =>
            {
                if (response.success || response.status !== "failed")
                {
                    setHouseholdEleRates(response.result.household);
                    setCompanyEleRates(response.result.company);
                } else
                {
                    setHouseholdEleRates(null);
                    setCompanyEleRates(null);
                }
            });
    };

    const fetchCircuitBreakersList = (rateId, phase) =>
    {
        fetch("/api/circuit-breakers-list", {
            method: "POST",
            body: JSON.stringify({ rateId, phase }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(response =>
            {
                if (response.success || response.status !== "failed")
                {
                    setCircuitBreakers(
                        response.result.map(rate => ({
                            id: rate.id,
                            name: rate.name,
                            requiresMaxAmperes: rate.requiresMaxAmperes,
                        }))
                    );
                } else
                {
                    setCircuitBreakers(null);
                }
            });
    };

    const setUrlParams = (allow = true) =>
    {
        const url = new URL(window.location.href);

        if (allow)
        {
            let data = (isSelectedGas ? [
                "g",
                postCode,
                consumption,
                null, //consumptionLowTariff,
                null, //consumptionHighTariff,
                selectedUnit.value === "mwh" ? "m" : "k",
                null, //breakerId
                null, //rateId
                offerCode,
                competitionOfferCodes.gas,
                isHousehold ? 'd' : 'f',
                null, //phase
                null, //maxAmperes
            ] : [
                "e",
                postCode,
                null, //consumption,
                consumptionLowTariff,
                consumptionHighTariff,
                selectedUnit.value === "mwh" ? "m" : "k",
                breakerId,
                rateId,
                offerCode,
                competitionOfferCodes.ele,
                isHousehold ? 'd' : 'f',
                phase,
                maxAmperes,
            ]).join("|");

            const b64 = btoa(data);
            url.searchParams.set("k", b64);
        }
        else
        {
            url.searchParams.delete("k")
        }

        window.history.replaceState({}, '', url);
    }

    const processUrlParams = () =>
    {
        const params = new URLSearchParams(window.location.search);
        const calc = params.get("k");

        if (calc)
        {
            const dataString = atob(calc);
            const data = dataString.split("|");

            const commodity = data[0];

            if (commodity === "g")
            {
                setSelected(0);
                setConsumption(parseFloat(data[2]));
            }
            else
            {
                setSelected(1);
                setConsumptionLowTariff(parseFloat(data[3]));
                setConsumptionHighTariff(parseFloat(data[4]));
                setBreakerId(parseInt(data[6]));
                setRateId(parseInt(data[7]));
                setPhase(parseInt(data[11]));
                setMaxAmperes(parseInt(data[12]));
            }

            setPostCode(data[1]);
            setActiveOfferCode(data[8]);
            setUnit(data[5] === "m" ? "mwh" : "kwh");
            setfetchCalculationRequested(true);
            setType(data[10] === 'd' ? 'domacnost' : 'firma');
        }
    }

    const setGaEvent = (data, result) =>
    {
        const isSelectedGas = data.commodity === "gas";
        const totalPrice = getTotalPrice(result);

        if (isSelectedGas)
        {
            const consumption = data.consumptionUnit === "mwh" ? data.consumption : data.consumption / 1000;

            const eventData = {
                section: analyticsData.section,
                url: document.location.href,
                consumption,
                psc: data.postcode,
                price: totalPrice,
            }
            window.mndAnalytics?.logGasCalculationEvent(eventData);
        }
        else 
        {
            const consumptionNt = data.consumptionUnit === "mwh" ? data.consumptionLowTariff : data.consumptionLowTariff / 1000;
            const consumptionVt = data.consumptionUnit === "mwh" ? data.consumptionHighTariff : data.consumptionHighTariff / 1000;
            const tariffId = rate?.shortName || data.rateId;

            const eventData = {
                section: analyticsData.section,
                url: document.location.href,
                consumptionNt,
                consumptionVt,
                psc: data.postcode,
                price: totalPrice,
                tariffId,
            }
            window.mndAnalytics?.logElectricityCalculationEvent(eventData);
        }
    }

    const fetchCalculation = () =>
    {
        const isValid = validation();
        const isCompany = type === "firma";

        if (isValid)
        {
            setErrorMessage("");
            setCalculationLoading(true);
            setShowResult(true);
            setfetchCalculationRequested(false);

            const data = isSelectedGas ? ({
                commodity: 'gas',
                postcode: String(postCode),
                consumption,
                consumptionUnit: selectedUnit.value,
                offerCode,
                competitionOfferCode: isUsingBonus ? undefined : competitionOfferCodes.gas,
                isCompany,
            }) :
                ({
                    commodity: 'ele',
                    postcode: String(postCode),
                    consumptionLowTariff: allowsBothTariffs ? consumptionLowTariff : 0,
                    consumptionHighTariff,
                    circuitBreakerId: Number(breakerId),
                    rateId: Number(rateId),
                    consumptionUnit: selectedUnit.value,
                    offerCode,
                    competitionOfferCode: isUsingBonus ? undefined : competitionOfferCodes.ele,
                    isCompany,
                    circuitBreakerMaxCurrent: selectedBreaker?.requiresMaxAmperes || selectedBreaker === null ? maxAmperes : undefined,
                });

            fetch("/api/calculation", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
                .then(response => response.json())
                .then(response =>
                {
                    if (response.success || response.status !== "failed")
                    {
                        setCalculationResult(response.result);
                        setUrlParams();
                        setGaEvent(data, response.result);
                    } else
                    {
                        try
                        {
                            const responseMessage = '{"success' + response.message.split('{"success').pop();
                            const json = JSON.parse(responseMessage);
                            const errors = json.errors;
                            const message = errors.map(error => `${error.message}, (${error.sourceParameter})`).join(' ');

                            setErrorMessage(message);
                        }
                        catch (e)
                        {
                            console.info("error-info", e);

                            setErrorMessage("Chyba při zpracování výpočtu.");
                        }

                        setShowResult(false);
                    }
                    setCalculationLoading(false);
                }).catch(e =>
                {
                    console.info("error-info", e);

                    setShowResult(false);
                    setCalculationLoading(false);
                    setErrorMessage("Nepodařilo se zjistit výpočet se zadanými parametry.");
                });
        }
    }

    const changeCommodity = (swch) =>
    {
        setErrorMessage("");
        setSelected(swch);
        setErrorMessage("");
        setCalculationLoading(false);
        setShowResult(false);
    }

    const setActiveOffer = (offer) =>
    {
        setErrorMessage("");
        setActiveOfferCode(offer.offerCode);

        const event = new CustomEvent("activeOfferProduct", { detail: { offerCode: offer.offerCode, commodity: offer.commodity } });

        window.dispatchEvent(event);
    }

    const changeParameters = () =>
    {
        setUrlParams(false);
        setShowResult(false);
        setErrorMessage("");
    }

    const changePhase = (phase) =>
    {
        setPhase(phase);
        setBreakerId(null);
        setErrorMessage("");
    }

    const changeType = (type) =>
    {
        setType(type);
        setRateId(null);
        setErrorMessage("");
    }

    const getTotalPrice = (calculationResult) =>
    {
        const mndTotalPrice = calculationResult?.mnd.totalPrice;
        const taxCoefficient = calculationResult?.taxCoefficient;

        const totalPrice = (mndTotalPrice && taxCoefficient && round(mndTotalPrice * taxCoefficient)) || null;
        return totalPrice;
    }

    const getPricePerMonth = (calculationResult) =>
    {
        const taxCoefficient = calculationResult?.taxCoefficient;
        const mndTotalPrice = calculationResult?.mnd.totalPrice;
        const pricePerMonth = (mndTotalPrice && round(mndTotalPrice * taxCoefficient / 12)) || null;
        return pricePerMonth;
    }

    return {
        selected,
        changeCommodity,
        showResult,
        changeParameters,
        commodity,
        eleRates,
        circuitBreakers,
        breakerId,
        setBreakerId,
        selectedBreaker,
        rateId,
        setRateId,
        isCalculationLoading,
        fetchCalculation,
        consumption,
        isConsumptionValid,
        setConsumption,
        consumptionLowTariff,
        isConsumptionLowTariffValid,
        setConsumptionLowTariff,
        consumptionHighTariff,
        isConsumptionHighTariffValid,
        setConsumptionHighTariff,
        postCode,
        setPostCode,
        calculationResult,
        errorMessage,
        isValid,
        isFixed,
        offerCode,
        activeOfferCode,
        setActiveOffer,
        selectedUnit,
        setUnit,
        unitsOptions,
        ratesOptions,
        rate,
        allowsBothTariffs,
        textList,
        type,
        changeType,
        phase,
        changePhase,
        maxAmperes,
        setMaxAmperes,
        totalPrice: getTotalPrice(calculationResult),
        pricePerMonth: getPricePerMonth(calculationResult),
    };
};
