import React from "react";
import dropDown from '../../../images/arrow-down.png';

export const Select = (props) =>
{
    const { label, name, options, placeholder, customClass, value, onChange, disabled } = props;

    return <>
        {label ? <label className="text-exploratory-green-400 mb-2 text-[14px] block" for={name}>
            {label}
        </label> : null}

        <select
            name={name}
            placeholder={placeholder}
            className={`w-full text-expertise-gray border-none bg-[length:20px_20px] bg-[right_10px_top_10px] bg-no-repeat text-[14px] pr-8${customClass ? " " + customClass : ''}`}
            css={{
                backgroundImage: `url(${dropDown})`,
            }}
            onChange={onChange}
            value={value}
            disabled={disabled}
        >
            {!!placeholder ? <option
                key="placeholder"
                value=""
                disabled
            >
                {placeholder}
            </option> : null}

            {options.map(item => (
                <option key={item.value} value={item.value}>
                    {item.label}
                </option>
            ))}
        </select>
    </>
}